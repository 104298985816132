import React, {useContext, useEffect} from 'react';
import Loader3 from 'components/common/Loader/loader3';
import VimeoComponent from './Vimeo';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import './video.scss';
import './videoMobile.scss';
import LessonsNavigationButton from "../../../../common/LessonsNavigationButton";
import {WebinarContext} from "../../../../../constants/contexts";
import {updateWebinarCurrentExercise} from "../../../../../services/api";

const DESCRIPTION = 'description';
const TASKS_LIST = 'task list';

export default function VideoDisplay({id, loading, content, type, componentToDisplay, toggleComponent, onBackClick, onNextClick, data, isPause = false}) {
  const auth = useSelector(state => state.auth.authenticated);
  const {t} = useTranslation();
  const {context, setContext} = useContext(WebinarContext);

  useEffect(() => {
    if (type === 'webinar') {
      setContext({...context, correctAnswerPromise: (resolve) => {
          updateWebinarCurrentExercise(context.accessToken, true)
          return resolve('do_correct')
        }
      })
    }
  }, [])

  const getVideoSrc = () => {
    if(type === 'course') {
      return data.vimeo_video
    } else {
      return content.vimeoLink
    }
  };

  if(!loading && type === 'course' && !data.vimeo_video) {
    return (
      <div className="chess-display__container chess-display__container--buttons">
        <div style={{flexGrow: '1', display: 'flex', justifyContent: "center"}}>
          <img style={{width: '280px', padding: '20px 0'}} src={require('../../../../../assets/img/logo.svg')}/>
        </div>
      </div>
    )
  }

  return (
    <div className={`chess-display__container chess-display__container--buttons ${type === 'webinar' && 'chess-display__container_webinar'}`}>
      <div className='video-display'>
        <div className={`${type === 'course' || type === 'webinar' ? '' : 'chess-display__container chess-display__container--buttons'}`}>
        {loading ?
          <Loader3/>
          :
          <>
            {getVideoSrc() && <VimeoComponent id={id} isPause={isPause} data={data} type={type} exerciseData={content} videoSrc={getVideoSrc()} auth={auth}/>}
          </>
        }
        </div>
      </div>
      {type !== 'course' && type !== 'webinar' &&
        <div className='chess-display__buttons-container'>
          <LessonsNavigationButton loading={loading} onClick={onBackClick}>{t('exercises.back')}</LessonsNavigationButton>
          <LessonsNavigationButton loading={loading} onClick={onNextClick}>{t('exercises.next')}</LessonsNavigationButton>
         </div>
      }
      {
        type !== 'webinar' &&
          <div className='chess-display__buttons-container chess-display__buttons-container--mobile'>
            <div className='chess-display__toggle-buttons-container'>
              <button
                  className={`chess-display__toggle-button ${componentToDisplay === TASKS_LIST ? 'chess-display__toggle-button--active' : ''}`}
                  type='button' onClick={() => toggleComponent(TASKS_LIST)}>
                {t('exercises.list')}
              </button>
              <button
                  className={`chess-display__toggle-button ${componentToDisplay === DESCRIPTION ? 'chess-display__toggle-button--active' : ''}`}
                  type='button' onClick={() => toggleComponent(DESCRIPTION)}>
                {t('exercises.description')}
              </button>
            </div>
          </div>
      }
    </div>
  )
};