import React, { useState } from 'react';

export const WebinarContext = React.createContext({
  correctAnswerPromise: (resolve) => {
    resolve('ok');
  },
  accessToken: '',
  userAnswer: null,
  hash: '',
  isHeaderShow: JSON.parse(localStorage.getItem('headerState')) ?? true,
  status: '',
  socket: {},
  isDurationRun: false,
});

//Это жёсткий костыль связанный с таймером длительности
export const DurationContext = React.createContext({
  isDurationRun: false,
});

export const DurationContextProvider = ({ children }) => {
  const [durationContext, setDurationContext] = useState({
    isDurationRun: false,
  });

  return (
    <DurationContext.Provider value={{ durationContext, setDurationContext }}>
      {children}
    </DurationContext.Provider>
  );
};

export const WebinarContextProvider = ({ children }) => {

    const [context, setContext] = useState({
        correctAnswerPromise: (resolve) => { resolve('ok') },
        accessToken: '',
        userAnswer: null,
        hash: '',
        status: '',
        isHeaderShow: JSON.parse(localStorage.getItem('headerState')) ?? true,
        isDurationRun: false,
        socket: {}
    });
    const _setContextDebug = (value) => {
        setContext(value);
    }
    return (
        <WebinarContext.Provider
            value={{ context, setContext: _setContextDebug }}>
            {children}
        </WebinarContext.Provider>
    )
}
