import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Chess from 'chess.js';
import Chessboard from 'chessboardjsx';
import {
  setBoardWidth,
  getStylesOfAvailableMoves,
  pgnParseToArray,
  isPromotion,
} from '../chessHelperFuntions';
import { BOARD_STYLE, CHESS_HINT_COLOR, DROP_SQUARE_STYLE, TRANSITION_DURATION } from '../ChessConst';
import { useSelector } from 'react-redux';
import DrawOverlay from './DrawOverlay';
import PieceSelectingPopup from './PieceSelectingPopup';
import { PIECES } from 'constants/chessPieces';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { WebinarContext } from '../../../constants/contexts';
import { updateWebinarCurrentExercise } from '../../../services/api';
import { getUnicodeSymbol } from './lib/getUnicodeSymbol';
import { sendCompleteExercise } from 'components/Pages/Webinar/lib/sendCompleteExercise';

const FindBestChessLogic = ({
  counter,
  startFen,
  pgn,
  successCallback,
  enableSound,
  showAvailableMoves,
  TaskText,
  orientation,
  title,
  points,
  difficulty,
  audioHint,
  chessBoardColor,
  serviceMode,
  id,
  onMakeMove,
  lessonId
}) => {
  const [fen, setFen] = useState('start');
  const [squareStyles, setSquareStyles] = useState({});
  const [pieceSquare, setPieceSquare] = useState('');
  const [halfMovesArray, setHalfMovesArray] = useState([]);
  const [totalMoves, setTotalMoves] = useState();
  const [currentMoveNumber, setCurrentMoveNumber] = useState(0);
  const [complete, setComplete] = useState(false);
  const [turn, setTurn] = useState(startFen.split(' ')[1] || '');
  const [attempts, setAttempts] = useState(0);
  const [incorrectMoveCount, setIncorrectMoveCount] = useState(0);
  const [game, setGame] = useState(null);
  const [promotionPopup, setPromotionPopup] = useState(false);
  const [promotionMove, setPromotionMove] = useState({});
  const [history, setHistory] = useState([]);
  const [undoHistory, setUndoHistory] = useState([]);
  const [exerciseMessage, setExerciseMessage] = useState('');
  const [intervalId, setIntervalId] = useState(null);
  const [width, setWidth] = useState(setBoardWidth(serviceMode));
  const { t } = useTranslation();
  const { context, setContext } = useContext(WebinarContext);
  const chessRef = useRef(null);

  const containerRef = useRef();
  const moveSound = useRef();
  const correct = useRef();
  const incorrect = useRef();

  const handlerResize = useCallback(() => {
    setWidth(setBoardWidth(serviceMode));
  }, [serviceMode]);

  useEffect(() => {
    window.addEventListener('resize', handlerResize);

    return () => {
      window.removeEventListener('resize', handlerResize);
    };
  }, [serviceMode, handlerResize]);

  useEffect(() => {
    let newGame;

    if (startFen) {
      newGame = new Chess(startFen);
      setGame(newGame);
      setFen(startFen);
    } else {
      newGame = new Chess();
      setGame(newGame);
    }

    let parsedPgn = pgnParseToArray(pgn);
    setHalfMovesArray(parsedPgn);
    setTotalMoves(parsedPgn.length);
    setTurn(newGame.turn());
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [startFen, pgn, intervalId]);

  useEffect(() => {
    if (serviceMode === 'webinar' && game) {
      if (context.userAnswer !== null && complete) {
        setExerciseMessage(
          t(context.userAnswer === 'true' ? 'webinar.correctlyDone' : 'webinar.incorrectlyDone')
        );
        context.userAnswer === 'true' ? playMoveSound(correct) : playMoveSound(incorrect);
      }
      setContext({
        ...context,
        correctAnswerPromise: (resolve, reject) => {
          if (!exerciseMessage) {
            setExerciseMessage(t('webinar.incorrectlyDone'));
            playMoveSound(incorrect);
          }
          onPlayClick(resolve);
        },
      });
    }
  }, [game, exerciseMessage]);

  useEffect(() => {
    if (totalMoves === currentMoveNumber) {
      if (serviceMode === 'webinar' && (context.userAnswer == null || context.userAnswer === 'true')) {
        setExerciseMessage(t('webinar.correctlyDone'));

        if (JSON.parse(localStorage.getItem('role')) !== 'teacher') {
          sendCompleteExercise(context.socket, { exercise_id: id, exercise_correct: true, info: {
              exerciseId: id,
              userAnswer: history.length && history,
              lessonId,
            }, });
        }

        updateWebinarCurrentExercise(context.accessToken, 'true');
        setContext({ ...context, userAnswer: 'true' });
      }
      serviceMode === 'default' && successCallback(attempts);
      playMoveSound(correct);
      setComplete(true);
    }
  }, [totalMoves, currentMoveNumber]);

  const playMoveSound = (ref) => {
    // enableSound && ref.current.load();
    enableSound &&
      ref.current &&
      ref.current.play &&
      typeof ref.current.play === 'function' &&
      ref.current.play();
  };

  const onPieceSelect = (piece) => {
    // if(complete) return null;
    let move = game.move({
      from: promotionMove.from,
      to: promotionMove.to,
      promotion: piece,
    });

    makeMove(move);
    setPromotionPopup(false);
  };

  const makeMove = (move) => {
    if (move === null) return;

    const correctMove = complete || move.san === halfMovesArray[currentMoveNumber];
    onMakeMove && onMakeMove(`${move.from} --- ${move.to}`, correctMove, getUnicodeSymbol(move?.piece || '', move?.color || ""));

    if (complete) {
      playMoveSound(moveSound);
      setFen(game.fen());
      setPieceSquare('');
      setTurn(game.turn());
      setSquareStyles({});
      setHistory(game.history());
      setUndoHistory([]);
      return true;
    } else if (move.san === halfMovesArray[currentMoveNumber]) {
      setFen(game.fen());
      setPieceSquare('');
      setTurn(game.turn);
      setSquareStyles({});
      //setIncorrectMoveCount(0);
      setHistory(game.history());
      playMoveSound(moveSound);
      setTimeout(() => makeOppositeMove(currentMoveNumber + 1), 0);
      setCurrentMoveNumber(currentMoveNumber + 1);
    } else {
      playMoveSound(incorrect);
      if (setContext) {
        setContext({ ...context, userAnswer: 'false' });
        if (JSON.parse(localStorage.getItem('role')) !== 'teacher') {
          sendCompleteExercise(context.socket,  { exercise_id: id, exercise_correct: false });
        }
         
      }
      if (serviceMode === 'webinar' && !exerciseMessage) {
        setExerciseMessage(t('webinar.incorrectlyDone'));
        updateWebinarCurrentExercise(context.accessToken, 'false');
      }
      game.undo();
      setIncorrectMoveCount(incorrectMoveCount + 1);
      setAttempts(attempts + 1);
      onHintDisplay();
    }
  };

  const onElementPlay = (el) => {
    let squareToHighlite = game.moves({ verbose: true }).filter((item) => item.san === el)[0];
    squareToHighlite &&
      setSquareStyles({
        [squareToHighlite.to]: { backgroundColor: CHESS_HINT_COLOR },
        [squareToHighlite.from]: { backgroundColor: CHESS_HINT_COLOR },
      });
    game.move(el);
    setFen(game.fen());
    setHistory(game.history());
    setPieceSquare('');
  };

  const onPlayClick = (resolve) => {
    let currentEl = 0;
    game.load(startFen);
    setFen(startFen);
    setHistory([]);
    setPieceSquare('');
    setCurrentMoveNumber(0);
    setSquareStyles({});

    let timerId = setInterval(() => {
      if (halfMovesArray[currentEl]) {
        onElementPlay(halfMovesArray[currentEl], timerId);
        currentEl += 1;
      } else if (!halfMovesArray[currentEl]) {
        setSquareStyles({});
        clearInterval(timerId);
        return setTimeout(() => resolve(complete ? 'correct' : 'error'), 1000);
      }
    }, 2400);
    setIntervalId(timerId);
  };

  const makeOppositeMove = (moveNumber) => {
    let move = game.move(halfMovesArray[moveNumber]);
    if (move) {
      setSquareStyles({
        [move.from]: { backgroundColor: CHESS_HINT_COLOR },
        [move.to]: { backgroundColor: CHESS_HINT_COLOR },
      });

      setTimeout(() => {
        setFen(game.fen());
        setPieceSquare('');
        setTurn(game.turn);
        setHistory(game.history());
        setCurrentMoveNumber(moveNumber + 1);
        playMoveSound(moveSound);
      }, 500);

      setTimeout(() => {
        setSquareStyles({});
      }, 1000);
    }
  };

  const onHintDisplay = () => {
    if (incorrectMoveCount >= 3 && serviceMode !== 'webinar') {
      onHintClick();
    }
  };

  const onDrop = ({ sourceSquare, targetSquare }) => {
    if (isPromotion({ chess: game, move: { from: sourceSquare, to: targetSquare } })) {
      setPromotionPopup(true);
      setPromotionMove({ from: sourceSquare, to: targetSquare });
    } else {
      let move = game.move({
        from: sourceSquare,
        to: targetSquare,
      });

      return makeMove(move);
    }
  };

  const onSquareClick = (square) => {
    let moves = game.moves({
      square: square,
      verbose: true,
    });

    setPieceSquare(square);

    if (showAvailableMoves) {
      setSquareStyles({
        ...getStylesOfAvailableMoves(moves),
        [square]: { boxShadow: 'inset 0 0 0 2px rgb(57, 91, 93)' },
      });
    } else {
      setSquareStyles({ [square]: { boxShadow: 'inset 0 0 0 2px rgb(57, 91, 93)' } });
    }

    if (isPromotion({ chess: game, move: { from: pieceSquare, to: square } })) {
      setPromotionMove({ from: pieceSquare, to: square });
      setPromotionPopup(true);
    } else {
      let move = game.move({
        from: pieceSquare,
        to: square,
      });

      return makeMove(move);
    }
  };

  const onHintClick = () => {
    let squareToHighlite = game
      .moves({ verbose: true })
      .filter((el) => el.san === halfMovesArray[currentMoveNumber])[0];
    squareToHighlite &&
      setSquareStyles({
        [squareToHighlite.to]: { backgroundColor: CHESS_HINT_COLOR },
        [squareToHighlite.from]: { backgroundColor: CHESS_HINT_COLOR },
      });
  };

  const onBackClick = () => {
    let undo = game.undo();
    setUndoHistory(undo ? [undo, ...undoHistory] : undoHistory);
    setFen(game.fen());
    setPieceSquare('');
    setTurn(game.turn());
    setSquareStyles({});
    setHistory(game.history());
  };

  const onNextClick = () => {
    if (undoHistory.length > 0) {
      let move = game.move(undoHistory[0].san);
      if (move) {
        let arr = [...undoHistory];
        arr.shift();
        setUndoHistory(arr);
        setFen(game.fen());
        setPieceSquare('');
        setTurn(game.turn());
        setSquareStyles({});
        setHistory(game.history());
      }
    }
  };

  return (
    <>
      <div className='chess-display__chessboard' ref={containerRef}>
        {/*{complete && <div className='chess-display__description-overlay'/>}*/}
        {window.innerWidth > 300 && (
          <DrawOverlay
            ref={chessRef}
            squareWidth={setBoardWidth(serviceMode) / 8}
            containerRef={containerRef}
            fen={fen}
          />
        )}
        <div ref={chessRef}>
          <Chessboard
            id='BestMove'
            orientation={orientation}
            calcWidth={() => width}
            position={fen}
            onDrop={onDrop}
            undo={true}
            lightSquareStyle={chessBoardColor.LIGHT}
            darkSquareStyle={chessBoardColor.DARK}
            boardStyle={BOARD_STYLE}
            squareStyles={squareStyles}
            onSquareClick={onSquareClick}
            dropSquareStyle={DROP_SQUARE_STYLE}
            pieces={PIECES}
            transitionDuration={TRANSITION_DURATION}
          />
        </div>
      </div>
      <TaskText
        isCourse={serviceMode !== 'webinar'}
        counter={counter}
        turn={turn}
        attempts={attempts}
        title={title}
        difficulty={difficulty}
        audioHint={audioHint}
        complete={complete}
        onBackClick={onBackClick}
        onNextClick={onNextClick}
        history={history}
        undoHistory={undoHistory}
      />
      {promotionPopup && <PieceSelectingPopup onSelect={onPieceSelect} />}
      {serviceMode === 'webinar' && exerciseMessage && (
        <div
          className={classnames('chess-display_webinar__message', {
            'chess-display_webinar__message_incorrect': !complete || context.userAnswer === 'false',
            'chess-display_webinar__message_correct': context.userAnswer === 'true',
          })}>
          {exerciseMessage}
          <p>
            {context.userAnswer === 'true'
              ? `+${points} ${t('webinar_rating.points')}`
              : `0 ${t('webinar_rating.points')}`}
          </p>
        </div>
      )}
      <audio id='audio' src={require('../../../assets/audio/move.mp3')} ref={moveSound} />
      <audio id='audio' src={require('../../../assets/audio/correct.wav')} ref={correct} />
      <audio id='audio' src={require('../../../assets/audio/incorrect.wav')} ref={incorrect} />
    </>
  );
};

export default function FindBestChessTable({
  counter,
  startFen,
  pgn,
  successCallback,
  TaskText,
  title,
  difficulty,
  audioHint,
  id,
  serviceMode,
  points,
  onMakeMove,
  lessonId
}) {
  const sound = useSelector((state) => state.settings.sound);
  const moves = useSelector((state) => state.settings.moves);
  const chessBoardColor = useSelector((state) => state.settings.chessBoardColor);

  const orientationParser = () => {
    const side = startFen.split(' ')[1];
    return side === 'w' ? 'white' : 'black';
  };

  return (
    <FindBestChessLogic
      counter={counter}
      startFen={startFen}
      pgn={pgn}
      successCallback={successCallback}
      orientation={orientationParser()}
      title={title}
      points={points}
      id={id}
      difficulty={difficulty}
      serviceMode={serviceMode}
      TaskText={TaskText}
      audioHint={audioHint}
      enableSound={sound}
      showAvailableMoves={moves}
      chessBoardColor={chessBoardColor}
      onMakeMove={onMakeMove}
      lessonId={lessonId}
    />
  );
}
