import React, { useState, useRef, useEffect, useContext, useCallback, useMemo } from 'react';
import './testRadio.scss';
import './testRadioMobile.scss';
import { useSelector } from 'react-redux';
import { WebinarContext } from '../../../../../constants/contexts';
import classnames from 'classnames';
import { updateWebinarCurrentExercise } from '../../../../../services/api';
import { useTranslation } from 'react-i18next';
import ExerciseTitle from './ExerciseTitle/ExerciseTitle';
import { sendCompleteExercise } from 'components/Pages/Webinar/lib/sendCompleteExercise';

const TestRadio = ({
                     isCourse,
                     question,
                     lessonId,
                     answers = [],
                     title,
                     successCallback,
                     mode,
                     id,
                     type,
                     points,
                     counter,
                     onMakeMove,
                   }) => {
  const sound = useSelector(state => state.settings.sound);
  const [attempts, setAttempts] = useState(0);
  const [scrollHint, setScrollHint] = useState(false);
  const [exerciseMessage, setExerciseMessage] = useState({ message: '', correct: false });
  const correct = useRef();
  const incorrect = useRef();
  const { t } = useTranslation();
  const list = useRef();
  const { context, setContext } = useContext(WebinarContext);
  const [selectedItem, setSelectedItem] = useState(context && context.userAnswer ? context.userAnswer : 0);

  useEffect(() => {
    if (mode === 'webinar') setContext({
      ...context, correctAnswerPromise: (resolve, reject) => {
        exerciseEnd(resolve);
      },
    });
  }, [exerciseMessage.message]);


  const exerciseEnd = useCallback((resolve) => {
    if (selectedItem) {
      return setTimeout(() => resolve('true'), 1000);
    } else {
      const el = answers.find(item => item.is_correct);
      setSelectedItem(el.answer);
      if (!exerciseMessage.message) {
        setExerciseMessage({ message: t('webinar.incorrectlyDone'), correct: false });
      }
      sound && incorrect.current.load();
      sound && incorrect.current.play();
      return setTimeout(() => resolve('error'), 3000);
    }
  }, [selectedItem, exerciseMessage.message]);


  const onChange = (el) => {
    if (mode === 'webinar' && context.userAnswer !== null) return false;

    setSelectedItem(el.answer);
    onMakeMove && onMakeMove(`${JSON.stringify(el.answer)}`, el.is_correct);

    if (el.is_correct) {
      sound && correct.current.load();
      sound && correct.current.play();
      if (mode === 'webinar') {
        setExerciseMessage({ message: t('webinar.correctlyDone'), correct: true });
        if (JSON.parse(localStorage.getItem('role')) !== 'teacher') {
          sendCompleteExercise(context.socket, {
            exercise_id: id, exercise_correct: true, info: {
              exerciseId: id,
              userAnswer: el,
              question,
              lessonId,
            },
          });
        }
      } else if (mode === 'default') {
        successCallback(attempts);
      }
    } else {
      sound && incorrect.current.load();
      sound && incorrect.current.play();
      if (mode === 'default') setAttempts(attempts + 1);
      else {
        if (JSON.parse(localStorage.getItem('role')) !== 'teacher') {
          sendCompleteExercise(context.socket, { exercise_id: id, exercise_correct: false });
        }
        setExerciseMessage({ message: t('webinar.incorrectlyDone'), correct: false });
      }
    }

    if (mode === 'webinar') {
      updateWebinarCurrentExercise(context.accessToken, el.answer);
      setContext({ ...context, userAnswer: el.answer });
    }

  };

  useEffect(() => {
    if (list) {
      if (list.current) {
        list.current.onscroll = (e) => {
          let pos = (list.current.scrollTop || list.current.scrollTop) + list.current.offsetHeight;
          let max = list.current.scrollHeight;
          if (pos === max) {
            setScrollHint(false);
          }
        };
      }
    }
  }, []);

  useEffect(() => {
    if (list) {
      if (list.current) {
        list.current.scrollHeight > list.current.offsetHeight && setScrollHint(true);
      }
    }
  }, [list]);


  return (
    <div className={classnames('chess-display__description chess-display__description--repeat', {
      'chess-display__description_webinar': mode === 'webinar',
      'chess-display__description--picture': type === 'picture',
    })} style={{ flex: 'auto' }}>
      <ExerciseTitle title={title} counter={counter} isCourse={isCourse} />
      <p className="chess-display__name">{question}</p>
      {/*<span className='chess-display__dotes login__dotes login__dotes--task'/>*/}
      <ul className="chess-display__test-list" ref={list}>
        {scrollHint && mode === 'default' && <li className="chess-display__scroll-hint" />}
        {answers.map((el, index) =>
          <li
            className={classnames('chess-display__test-item', {
              'chess-display__test-label--warn': !el.is_correct && selectedItem === el.answer,
              'chess-display__test-label--correct': (mode === 'webinar' && el.is_correct && selectedItem !== 0) || (mode === 'default' && el.is_correct && selectedItem === el.answer),
            })}
            key={'radio' + index}
          >
            <input
              className={classnames({ 'chess-display__test-radio': !(!!(mode === 'webinar' && context.userAnswer)) })}
              type="radio"
              name="quest"
              onChange={() => onChange(el)}
              id={`${index}`}
            />
            <label className="chess-display__test-label" htmlFor={`${index}`}>{el.answer}</label>
          </li>,
        )}
      </ul>
      {mode === 'webinar' && exerciseMessage.message &&
        <div className={classnames('chess-display_webinar__message', {
          'chess-display_webinar__message_correct': exerciseMessage.correct,
          'chess-display_webinar__message_incorrect': !exerciseMessage.correct,
        })}>
          {exerciseMessage.message}
          <p>{exerciseMessage.correct ? `+${points} ${t('webinar_rating.points')}` : `0 ${t('webinar_rating.points')}`}</p>
        </div>
      }
      <span className="chess-display__dotes chess-display__dotes--mobile login__dotes login__dotes--task" />
      <audio id="audio" src={require('../../../../../assets/audio/correct.wav')} ref={correct} />
      <audio id="audio" src={require('../../../../../assets/audio/incorrect.wav')} ref={incorrect} />
    </div>
  );
};

export default React.memo(TestRadio);
