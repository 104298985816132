import React, { useContext, useEffect, useState } from 'react';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { PauseImg } from '../../../../../../assets/img/webinar';
import { WebinarContext } from '../../../../../../constants/contexts';
import classnames from 'classnames';

const Pause = ({ pauseTime, endTimerFunc, exercise_id, isPauseVisible }) => {
  const [duration, setDuration] = useState(pauseTime);
  const [hide, setHide] = useState(false);
  const { t } = useTranslation();
  const { context } = useContext(WebinarContext);

  useEffect(() => {
    context.socket.emit('pause', {});

    context.socket.on('pause', (pause) => {
      if (pause) {
        setDuration(pause);
      } else {
        endTimerFunc(true);
      }
    });

    return () => {
      context.socket.off('pause', (pause) => setDuration(pause));
    };
  }, []);

  return isPauseVisible ? (
    <div className={style.pause}>
      <div
        className={classnames(style['pause-body'], {
          [style['pause-body_hidden']]: hide,
        })}>
        <p className={style['pause-body__title']}>{t('webinar.exerciseEnd')}</p>
        <p className={style['pause-body__nextStart']}>
          {t('webinar.nextExerciseStart')}
          <span>{duration?.slice(3)}</span>
        </p>
        <PauseImg />
        <button className={style.pause__toggle} onClick={() => setHide(!hide)}>
          {hide ? t('webinar.show') : t('webinar.hide')}
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default React.memo(Pause, (prevProps, nextProps) => {
  return prevProps.pauseTime === nextProps.pauseTime && prevProps.isPauseVisible === nextProps.isPauseVisible;
});
