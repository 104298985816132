import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './testCheckbox.scss';
import './testCheckboxMobile.scss';
import { useTranslation } from "react-i18next";
import { showErrorMessage } from "helpers/notifications";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { WebinarContext } from "../../../../../constants/contexts";
import { updateWebinarCurrentExercise } from "../../../../../services/api";
import { sendCompleteExercise } from 'components/Pages/Webinar/lib/sendCompleteExercise';

const TestCheckbox = ({ question, answers = [], title, successCallback, mode, id, lessonId, type, points, onMakeMove }) => {
  const [attempts, setAttempts] = useState(0);
  const sound = useSelector(state => state.settings.sound);
  const { t } = useTranslation();
  const correct = useRef();
  const incorrect = useRef();
  const { context, setContext } = useContext(WebinarContext);
  const [isSubmit, setSubmit] = useState(!!(context && context.userAnswer));
  const [exerciseMessage, setExerciseMessage] = useState({ message: '', correct: false });
  const [selectedVariants, setSelectedVariants] = useState(context && context.userAnswer ? context.userAnswer : []);

  const getNumberOfCorrectAnswers = () => {
    return answers.filter(el => el.is_correct === true).length;
  };

  useEffect(() => {
    if (mode === 'webinar') {
      setContext({
        ...context, correctAnswerPromise: (resolve, reject) => {
          exerciseEnd(resolve);
        }
      })
    }
  }, [isSubmit]);


  const exerciseEnd = useCallback((resolve) => {
    if (!isSubmit) onCheckClick();
    return setTimeout(() => resolve('true'), 4000)
  }, [isSubmit])


  const onChange = (answer) => {
    if (mode === 'webinar' && context.userAnswer !== null) return false;
    if (selectedVariants.some(item => item.answer === answer)) {
      setSelectedVariants(...selectedVariants.filter(item => item.answer !== answer));
    } else {
      setSelectedVariants([...selectedVariants, answer]);
    }
  };

  const onCheckClick = () => {
    if (mode === 'webinar' && context.userAnswer) return false;
    setSubmit(true)

    const selectedAnswers = answers.filter(el => selectedVariants.includes(el.answer));

    if (mode === 'webinar') {
      updateWebinarCurrentExercise(context.accessToken, selectedVariants)
      setContext({ ...context, userAnswer: selectedVariants })
    }

    const isCorrect = selectedAnswers.every(el => el.is_correct === true) && getNumberOfCorrectAnswers() === selectedAnswers.length;

    try {
      onMakeMove && onMakeMove(`${JSON.stringify(selectedAnswers.map(el => el?.answer).join(', '))}`, isCorrect);
    } catch {
      console.error('chekboxes', selectedAnswers);
    }

    if (isCorrect) {
      sound && correct.current.load();
      sound && correct.current.play();
      if (mode === 'webinar') {
        setExerciseMessage({ message: t('webinar.correctlyDone'), correct: true })
        if (JSON.parse(localStorage.getItem('role')) !== 'teacher') {
          sendCompleteExercise(context.socket, { exercise_id: id, exercise_correct: true, info: {
              exerciseId: id,
              userAnswer: selectedAnswers,
              question,
              lessonId,
            }, });
        }
      }
      else {
        mode === 'default' && successCallback(attempts);
      }
    } else {
      sound && incorrect.current.load();
      sound && incorrect.current.play();
      if (mode === 'default') {
        setAttempts(attempts + 1);
        showErrorMessage(t('exercises.wrongAnswer'))
      }
      else {
        if (JSON.parse(localStorage.getItem('role')) !== 'teacher') {
          sendCompleteExercise(context.socket, { exercise_id: id, exercise_correct: false });
        }

        setExerciseMessage({ message: t('webinar.incorrectlyDone'), correct: false })
      }
    }
  };


  return (
    <div className={classnames('chess-display__description chess-display__description--repeat', {
      'chess-display__description_webinar': mode === 'webinar',
      'chess-display__description--picture': type === 'picture'
    })}>
      <p className={classnames('chess-display__title', {
        'chess-display__title_webinar': mode === 'webinar'
      })}>{title}</p>
      <p className='chess-display__name'>{question}</p>
      {/*<span className='chess-display__dotes login__dotes login__dotes--task'/>*/}
      <ul className={classnames('chess-display__test-list chess-display__test-list--checkbox', {
        'chess-display__test-list_webinar': mode === 'webinar'
      })}>
        {answers.map((el, index) =>
          <li className={classnames('chess-display__test-item', {
            'chess-display__test-label--warn': !el.is_correct && isSubmit && selectedVariants.includes(el.answer),
            'chess-display__test-label--correct': el.is_correct && isSubmit
          })} key={el.id}>
            <input className='chess-display__test-checkbox' onChange={() => onChange(el.answer)} value={el.is_correct} type='checkbox' name={el.answer} id={`${index}`} />
            <label className='chess-display__test-label-checkbox' htmlFor={`${index}`}>{el.answer}</label>
          </li>
        )}
      </ul>
      <button className='chess-display__check-button' type='button' onClick={onCheckClick}>{t('exercises.check')}</button>
      {mode === 'webinar' && exerciseMessage.message &&
        <div className={classnames('chess-display_webinar__message', {
          'chess-display_webinar__message_correct': exerciseMessage.correct,
          'chess-display_webinar__message_incorrect': !exerciseMessage.correct
        })}>
          {exerciseMessage.message}
          <p>{exerciseMessage.correct ? `+${points} ${t('webinar_rating.points')}` : `0 ${t('webinar_rating.points')}`}</p>
        </div>
      }
      {/*<span className='chess-display__dotes chess-display__dotes--mobile login__dotes login__dotes--task'/>*/}
      <audio id="audio" src={require('../../../../../assets/audio/correct.wav')} ref={correct} />
      <audio id="audio" src={require('../../../../../assets/audio/incorrect.wav')} ref={incorrect} />
    </div>
  )
}

export default React.memo(TestCheckbox, (prevProps, nextProps) => {
  return prevProps.mode === nextProps.mode &&
    prevProps.answers.length === nextProps.answers.length &&
    prevProps.title === nextProps.title
})
